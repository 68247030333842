import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { PageComponent } from '@root/pages/Type';
import { SuccessIcon } from '@components/Ui/Icons/Index';
import styles from './styles.module.scss';

const Redirecting: PageComponent = () => {
    return (
        <div className={classNames(styles.login_container, styles.open_login)}>
            <div className={styles.login_inner}>
                <div className="row">
                    <div className={`col-12 ${styles.login_success_container}`}>
                        <SuccessIcon />
                        <h4>
                            <Trans i18nKey="redirect.title" ns="userAuth">
                                Prisijungiama
                            </Trans>
                        </h4>
                        <p>
                            <Trans i18nKey="redirect.description" ns="userAuth">
                                Jus netrukus busite nukreipti i tolimesni
                                valdyma.
                            </Trans>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Redirecting;
