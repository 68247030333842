import { MainLayout } from '@root/components/layouts/Index';
import NavigationHeader from '@root/components/NavigationHeader';
import { useMemo } from 'react';
import { PageComponent } from '../Type';
import { useRouter } from 'next/router';

const useAuthNavigationHeader = () => {
    const router = useRouter();
    const { query } = router;
    const closeHref = useMemo(() => {
        if (query.backTo && typeof query.backTo === 'string') {
            return query.backTo;
        }
        return 'landing_home';
    }, [query]);
    const closeParams = useMemo(() => {
        return undefined;
    }, []);
    return {
        closeHref,
        closeParams,
    };
};

const AuthLayout: PageComponent = ({ children }) => {
    const close = useAuthNavigationHeader();
    return (
        <MainLayout>
            <NavigationHeader showUseButtons={false} isOpen {...close} />
            {children}
        </MainLayout>
    );
};

export default AuthLayout;
